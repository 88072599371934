import { Component, AfterViewInit, OnDestroy, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-boundary-canvas'; // Import the BoundaryCanvas plugin
import { MarkerDataService } from 'src/app/shared/service/makerservice/markerDataService';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements AfterViewInit, OnDestroy {
  @Input() markers: { lat: number, lng: number, type: any, data: any }[] = [];
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;
  private map: L.Map;
  private markersSubscription: Subscription;
  @ViewChild('detailModal') mapsContent: TemplateRef<any>;
  MARKER_TYPE_RESSOURCE: string = "ressource";
  MARKER_TYPE_VALORISATION: string = "valorisation";
  details: any;
  iconCentreUrl: string = "../assets/img/icon-centre.png";
  iconDefaultRessourceUrl: string = "../assets/img/logo-obgrm.png";

  // Définir une icône personnalisée
  private customIcon = L.icon({
    iconUrl: '../assets/img/logo-obgrm.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png', // Ombre du marqueur
    shadowSize: [41, 41] // Taille de l'ombre
  });

  constructor(private markerDataService: MarkerDataService, private modalService: NgbModal) { }

  ngAfterViewInit(): void {
    this.initMap();
    this.subscribeToMarkerUpdates();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromMarkerUpdates();
    if (this.map) {
      this.map.remove();
    }
  }

  private initMap(): void {
    if (this.mapContainer && this.mapContainer.nativeElement) {
      // Initialize the map centered on Benin
      const beninCenter: L.LatLngTuple = [9.3077, 2.3158];

      this.map = L.map(this.mapContainer.nativeElement, {
        center: beninCenter,
        zoom: 7,
        minZoom: 7,
        maxZoom: 13
      });

      this.loadBeninGeoJSON();
      this.updateMap();
    } else {
    }
  }

  private loadBeninGeoJSON(): void {
    fetch('../../assets/ben.geojson')
      .then(response => response.json())
      .then(geoJSON => {
        const osmLayer = new (L.TileLayer as any).BoundaryCanvas('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          boundary: geoJSON,
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        });
        osmLayer.addTo(this.map);

        const beninLayer = L.geoJSON(geoJSON);
        this.map.fitBounds(beninLayer.getBounds());
      })
      .catch(error => console.error('Error loading Benin GeoJSON:', error));
  }

  private subscribeToMarkerUpdates(): void {
    this.markersSubscription = this.markerDataService.markers$.subscribe(markers => {
      this.markers = markers;
      this.updateMap();
    });
  }

  private unsubscribeFromMarkerUpdates(): void {
    if (this.markersSubscription) {
      this.markersSubscription.unsubscribe();
    }
  }

  //to update the map
  private updateMap(): void {
    if (!this.map || !this.markers) {
      return;
    }
    //remove previous layers
    this.map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });

    //console.log("this.markers",  this.markers);
    
    //place each marker
    this.markers.forEach(marker => {
      let markerIconUrl: any = "";
      if (marker.data.type === this.MARKER_TYPE_VALORISATION) {
        markerIconUrl = this.iconCentreUrl ?? '';
      } else {
        markerIconUrl = marker.data.logo ?? this.iconDefaultRessourceUrl;
      }

      if (!markerIconUrl) {
        //console.error("Marker icon URL is missing. Using default icon.");
        markerIconUrl = this.iconCentreUrl; // Provide a fallback default URL
      }
  
      //console.log(markerIconUrl);

      let markerCustomIcon = L.icon({
        iconUrl: markerIconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png', // Ombre du marqueur
        shadowSize: [41, 41] // Taille de l'ombre
      });

      // Utiliser l'icône personnalisée
      const mapMarker = L.marker([marker.lat, marker.lng], { icon: markerCustomIcon }).addTo(this.map);

      // Create popup content
      const popupContent = this.createPopupContent(marker);

      // Ajouter une popup au marqueur
      mapMarker.bindPopup(popupContent)
        .on('popupopen', () => this.onPopupOpen(marker));

      // Afficher une popup au survol de la souris
      mapMarker.on('mouseover', function () {
        mapMarker.bindPopup(popupContent).openPopup();
      });

      // Masquer la popup au retrait de la souris
      // mapMarker.on('mouseout', function () {
      //   mapMarker.closePopup(); 
      // });

    });
  }//end updateMap

  private onPopupOpen(marker: { lat: number, lng: number, type: any, data: any, }): void {
    // Ajouter un écouteur d'événements pour le bouton dans la popup
    const button = document.getElementById(`details-btn-${marker.lat}-${marker.lng}`);
    if (button) {
      button.addEventListener('click', () => this.viewDetailOnRessource(marker));
    }
  }


  private createPopupContent(marker: { lat: number, lng: number, type: string, data: any, }): string {
    // Ajoutez un identifiant unique pour le bouton
    let pageContent: string = "";
   // console.log("data",marker);
    
    switch (marker.type) {
      
      //type ressource
      case this.MARKER_TYPE_RESSOURCE:
        pageContent = `
          <div style="padding="0px";" >
            <p style="font-size:18px"><strong>Ressource</strong> : ${marker.data.ressource ?? ''}</p>
            <p style="font-size:18px"><strong>Type</strong> : ${marker.data.type_ressource ?? ''}</p>
            <p style="font-size:18px"><strong>Département</strong> : ${marker.data.departement ?? ''}</p>
            <p style="font-size:18px"><strong>Commune</strong> : ${marker.data.communes ?? ''}</p>
            <p style="font-size:18px"><strong>Localité</strong> : ${marker.data.localite ?? ''}</p>
            <button id="details-btn-${marker.lat}-${marker.lng}" class="btn btn-primary">Voir Détails</button>
          </div>
        `;

        // <p><strong>Description</strong> : ${marker.data.description ?? ''}</p>
        break;

      //type ressource
      case this.MARKER_TYPE_VALORISATION:
        pageContent = `
          <div ">
            <p><strong>Nom</strong> : ${marker.data.nom ?? ''}</p>
            <p><strong>Département</strong> : ${marker?.data?.items?.arrondissement?.commune?.departement?.libelle ?? ''}</p>
            <p><strong>commune</strong> : ${marker?.data?.items?.arrondissement?.commune?.libelle ?? ''}</p>²
           <button id="details-btn-${marker.lat}-${marker.lng}" class="btn btn-primary">Voir Détails</button>
          </div>
        `;
      // <p><strong>Description</strong> : ${marker.data.description ?? ''}</p>

      default:
        break;
    }


    return pageContent;
  }




  viewDetailOnRessource(item: any) {
    this.details = item;

    //console.log("this.details",this.details);
    
    this.modalService.open(this.mapsContent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }
}
