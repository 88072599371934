<app-horizontaltopbar></app-horizontaltopbar><br><br><br><br>

<div class="">
<!-- 
    <div class="d-flex justify-content-center">
        <div class="col-lg-10">
            <div class="info-msg text-center">
                <i class="fa fa-info-circle"></i>
                Bienvenue sur notre plateforme dédiée aux services de l'Office Béninois de Recherche Géologique et
                Minière (OBRGM).
                Nous sommes ravis de vous accueillir et de vous présenter les différentes solutions
                que nous proposons pour répondre à vos besoins en matière de recherche et d'optimisation des ressources.
            </div>
        </div>
    </div> -->

    <div>

    </div>


</div>

<div class="container-fluid col-lg-10">
    <div class="row">
        <div class=" ">
            <div class="card">
                <div class="card-body">

                    <!-- <div class="d-flex justify-content-center  mb-md-2">
                        <h1>
                            <span>B</span>
                            <span>i</span>
                            <span>e</span>
                            <span>n</span>
                            <span>v</span>
                            <span>e</span>
                            <span>n</span>
                            <span>u</span>
                            <span>e</span> &nbsp;

                            <span>{{listenName?.nom}}</span>
                        </h1>

                    </div> -->

                    <!-- <div class="table-responsive mt-3 d-flex justify-content-center align-items-center">
                        <table class="table table-centered datatable dt-responsive nowrap justify-content-center" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                            <thead class="thead-light rwd-table">
                                <tr>
                                    <th id="upper">{{serviceName}}</th>
                                    <th colspan="19"></th>   
                                    <th id="upper">Actions</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of servicesData" title="Accéder" style="cursor: pointer;">
                                    <td id="upper">{{item?.libelle}}</td>
                                    <td colspan="19"></td>
                                    <td>
                                         <div > 
                                            <a id="upper" (click)="acessDashboard(item)" style="cursor: pointer;" class="button button--default">
                                                Accéder <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
                                            </a>
                                         </div> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    <div class="container my-5">
                        <div class="row justify-content-center g-4">
                            <div class="col-md-4" *ngFor="let item of servicesData; let i = index">
                                <div class="card-custom">
                                    <div class="circle-dot"></div>
                                    <img [src]="i % 2 === 0 ? 'assets/images/241.png': 'assets/images/24.png'  "
                                        width="200px" alt="{{item.libelle}}" class="img-fluid">
                                    <div class="separator-line"></div>
                                    <div class="content">
                                        <h3 style="font-size: 17px;" id="upper">{{ item.libelle }}</h3>
                                    </div>
                                    <button class="btn-custom" (click)="acessDashboard(item)">
                                        <span>
                                         Connecter   <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-line"></div>

                </div>
            </div>
        </div>
    </div>
</div>