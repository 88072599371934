<div class="breadcrumbs overlay">
  <div class="container">
    <div class="bread-inner">
      <div class="row">
        <div class="col-12">
          <h2>RECHERCHE</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" container mt-4">
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Filtre de recherche</h5>

          <!-- Filtre de Département -->
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Département</h5>
              <select class="form-select" name="departementItem" [(ngModel)]="selectedDepartement" id="departementItem"
                (change)="filterLocalisationResults()">
                <option [ngValue]="null">Sélectionner un département</option>
                <option *ngFor="let item of departementItems" [ngValue]="item">{{item.libelle}}</option>
              </select>
            </div>
          </div>

          <!-- Filtre de Ressource -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Ressource</h5>

              <select [(ngModel)]="selectedRessource" class="form-select" name="selectedRessource"
                id="selectedRessource" (change)="filterLocalisationResults()">
                <option [ngValue]="null">Sélectionner une ressource</option>
                <option *ngFor="let item of ressourceItems" [ngValue]="item">{{item.titre}}</option>
              </select>

            </div>
          </div>

          <div class="d-flex justify-content-center mb-3">
            <button (click)="filterLocalisationResults()" class="text-center btn btn-10">Rechercher
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Onglets Section à droite -->
    <div class="col-md-9">
      <p-tabView>
        <p-tabPanel header="Localisation">
          <div class="card d-flex justify-content-center align-items-center">
            <app-maps [markers]="markers" class="w-100 h-100"></app-maps>
            <span *ngIf="!loading"></span>
            <div class="loader text-center" *ngIf="loading"></div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Liste">

          <div class="card ">
            <div class="container">

              <div class="d-flex flex-column flex-md-row">
                <!-- Valorisation 2 Start -->
                <div class="container-fluid blog ">
                  <div class="container py-4">
                    <div class="row g-4 justify-content-center">
                      <div style="background-color: rgb(0 0 0 / 22%) !important"
                        class="col-md-4 col-lg-6 col-xl-4 wow fadeInUp card" data-wow-delay="0.1s"
                        *ngFor="let item of paginatedLocalisations">
                        <div class="blog-item rounded">
                          <div class="blog-img">
                            <img [src]="item.ressource.photo" style="height: 200px;" class="img-fluid w-100"
                              alt="Image">
                          </div>
                          <div class="blog-centent p-4">
                            <p href="#"><strong class="h4">Titre: </strong>{{item.ressource.titre}}</p>
                            <p href="#"><strong class="h4">Localisation: </strong>{{item.nom_localite}}</p>
                            <p class="my-4"> <strong>Type ressource: </strong> {{item.ressource.type_ressource.libelle}}
                            </p>
                            <p class="my-5"> <strong>Description: </strong> {{item.ressource.perspective}}</p>

                            <button id="bordure" (click)="goToResourceDetail(item.ressource.reference)"
                              class="btn btn-primary text-white"> En
                              savoir +</button>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center" *ngIf="paginatedLocalisations.length === 0">
                        <div class="container">
                          <div class="row">
                            <div class="text-center">
                              <h5 id="graycolor">Aucune ressource trouvée dans cette catégorie</h5>
                            </div>
                          </div>
                        </div>
      
                      </div>

                    </div>
                  </div>
                </div>
               
              </div>
            </div>


            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" />
          </div>
        </p-tabPanel>
      </p-tabView>
    </div><br><br>
  </div>
</div>

<p-toast />